// 2024 Modeos Guido

import { Category, Team } from "./Backend";

export const enum FichajeStatus {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
}

export enum FichajeRejectedBy {
  PHOTO_DNI = "PHOTO_DNI",
  PHOTO_SELFIE = "PHOTO_SELFIE",
  PHOTO_BOTH = "PHOTO_BOTH",
  FULL = "FULL",
}
export interface InfoDNI {
  name: string;
  surname: string;
  dni: string;
  birthday: string;
  gender: string;
  extra: {
    day: string;
    month: string;
    year: string;
  };
}

export interface PersonHistorial {}
export interface Person extends InfoDNI {
  id?: string;
  avatar?: string;
  dniImage?: string;
  historial?: PersonHistorial[];
  email?: string;
  habilitado?: boolean;
  active?: boolean;
  vetado?: boolean;
}

export interface Disciplina {
  id?: string;
  name: string;
  description: string;
}
export interface FuncionarType {
  id?: string;
  name: string;
  disciplinas: Disciplina[];
}
export interface PersonFichaje {
  id?: string;
  personId?: string;
  person: Person;
  team: Team;
  status: FichajeStatus;
  rejectedBy?: FichajeRejectedBy;
  reason?: string;
  active?: boolean;
  avatar?: string;
  dniImage?: string;
}
export interface PersonFichajePlayer extends PersonFichaje {
  id?: string;
  category: Category;
}

export interface PersonFichajeFuncionar extends PersonFichaje {
  id?: string;
  funcionarioType: FuncionarType;
}

export interface PersonFichajePlayerRequest {
  id?: string;
  personId?: string;
  avatar?: string;
  dniImage?: string;
  from?: "APP";
  categoryId?: string;
}

export interface PersonFichajeFuncionarioRequest {
  id?: string;
  personId?: string;
  avatar?: string;
  dniImage?: string;
  from?: "APP";
  funcionarioId?: string;
}

// OLD FICHAJE
export interface NewPlayer {
  id?: string;
  name: string;
  surname: string;
  dni: string;
  birthday: string;
  avatar: string;
  dniImage: string;
  photoPerson: string;
  gender: string;
  extra: {
    day: string;
    month: string;
    year: string;
  };
  fromApp: boolean;
  category: Category;
  team: Team;
}
