import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EliminarItem } from './eliminar-item.model';

@Component({
  selector: 'app-eliminar-item',
  templateUrl: './eliminar-item.component.html',
  styleUrls: ['./eliminar-item.component.css']
})
export class EliminarItemComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EliminarItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EliminarItem) {}

    public onNoClick(){
      this.dialogRef.close()
    }

  ngOnInit(): void {
  }

}
