export const PERMISOS_STR = [
'APPCLUBES_BO_ACCESOS',
'APPCLUBES_BO_FICHAJE',
'APPCLUBES_BO_RECATEGORIZACION',
'APPCLUBES_BO_CONFIGURACION',
'APPCLUBES_BO_JUGADORES',
'APPCLUBES_BO_NOTICIAS',
'APPCLUBES_BO_NOTIFICACIONES',
'ADM_USUARIOS'
]
export const PERMISOS = {
    APPCLUBES_BO_ACCESOS:'APPCLUBES_BO_ACCESOS',
    APPCLUBES_BO_FICHAJE:'APPCLUBES_BO_FICHAJE',
    APPCLUBES_BO_RECATEGORIZACION:'APPCLUBES_BO_RECATEGORIZACION',
    APPCLUBES_BO_CONFIGURACION:'APPCLUBES_BO_CONFIGURACION',
    APPCLUBES_BO_JUGADORES:'APPCLUBES_BO_JUGADORES',
    APPCLUBES_BO_NOTICIAS:'APPCLUBES_BO_NOTICIAS',
    APPCLUBES_BO_NOTIFICACIONES:'APPCLUBES_BO_NOTIFICACIONES',
    ADM_USUARIOS: 'ADM_USUARIOS'
}
