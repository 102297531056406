import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-pago-jugador",
  templateUrl: "./pago-jugador.component.html",
  styleUrls: ["./pago-jugador.component.css"],
})
export class PagoJugadorComponent implements OnInit {
  public jugadores = null;

  public checkPago: boolean = false;
  public comprobante = "";

  constructor(
    public dialogRef: MatDialogRef<PagoJugadorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.jugadores = data.jugadores;
    if (!this.jugadores.length) {
      this.dialogRef.close();
    }

    this.checkPago = this.jugadores[0].pago;
    this.comprobante = this.jugadores[0].pagoComprobante;

    if (this.jugadores.length > 1) {
      this.jugadores.forEach((jugador) => {
        if (jugador.pago !== this.checkPago) {
          this.checkPago = false;
          return;
        }
        if (jugador.pagoComprobante !== this.comprobante) {
          this.comprobante = "";
          return;
        }
      });
    }
  }

  ngOnInit(): void {}

  public cancel() {
    this.dialogRef.close();
  }
  public save() {
    this.dialogRef.close({
      ids: this.jugadores.map((x) => x.id),
      pago: this.checkPago,
      comprobante: this.comprobante,
    });
  }
}
