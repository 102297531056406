import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { DEFAULT_HEADERS } from "../constants/constants";
import { AuthenticationService } from "../auth/authentication.service";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(
    private httpClient: HttpClient,
    private auth: AuthenticationService
  ) {}

  public get<T>(url: string, criteria?): Observable<T> {
    return this.httpClient.get<T>(environment.baseUrl + url, {
      headers: DEFAULT_HEADERS,
      params: { ...criteria },
    });
  }

  public post<T>(url: string, body: any, token = true): Observable<T> {
    const obj = token ? { ...body } : body;
    return this.httpClient.post<T>(environment.baseUrl + url, obj, {
      headers: DEFAULT_HEADERS,
    });
  }

  public postUpload<T>(url: string, body: any, token = true): Observable<T> {
    return this.httpClient.post<T>(environment.baseUrl + url, body);
  }

  public postCSV(url: string, body: any, token = true): Observable<any> {
    const obj = token ? { ...body } : body;
    return this.httpClient.post(environment.baseUrl + url, obj, {
      headers: DEFAULT_HEADERS,
      responseType: "text",
    });
  }

  public put<T>(url: string, body: any, criteria?): Observable<T> {
    return this.httpClient.put<T>(environment.baseUrl + url, body, {
      headers: DEFAULT_HEADERS,
      params: criteria,
    });
  }

  public delete<T>(url: string, id): Observable<T> {
    return this.httpClient.delete<T>(`${environment.baseUrl}${url}/${id}`, {
      headers: DEFAULT_HEADERS,
    });
  }

  public getInMock<T>(url): Observable<T> {
    return this.httpClient.get<T>(url);
  }

  public postWithoutToken<T>(url: string, body: any): Observable<T> {
    return this.httpClient.post<T>(environment.baseUrl + url, body, {
      headers: DEFAULT_HEADERS,
    });
  }

  public getWithoutToken<T>(url: string, body: any): Observable<T> {
    return this.httpClient.get<T>(environment.baseUrl + url, {
      params: body,
      headers: DEFAULT_HEADERS,
    });
  }
}
