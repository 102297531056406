import { Component, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-historial-jugador",
  templateUrl: "./historial-jugador.component.html",
  styleUrls: ["./historial-jugador.component.css"],
})
export class HistorialJugadorComponent implements OnInit {
  public _historial = [];
  @Input() set historial(hist) {
    this._historial = hist?.map((hist) => ({
      ...hist,
      message: hist.message ? JSON.parse(hist.message) : null,
    }));
  }
  get historial() {
    return this._historial;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (!data.historial) return;

    this._historial = data.historial.map((hist) => ({
      ...hist,
      message: hist.message ? JSON.parse(hist.message) : null,
    }));
  }

  ngOnInit(): void {}
}
