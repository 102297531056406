import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { EliminarItemComponent } from "./eliminar-item/eliminar-item.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { ValidarJugadorComponent } from "./validar-jugador/validar-jugador.component";
import { NgxImageDisplayModule } from "@creativeacer/ngx-image-display";
import { LightboxModule } from "ngx-lightbox";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MatInputModule } from "@angular/material/input";
import { AgregarItemComponent } from "./agregar-item/agregar-item.component";
import { MatSelectModule } from "@angular/material/select";
import { HistorialJugadorComponent } from "./historial-jugador/historial-jugador.component";
import { MatCardModule } from "@angular/material/card";
import { PagoJugadorComponent } from "./pago-jugador/pago-jugador";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CredencialJugadorComponent } from "./credencial-jugador/credencial-jugador.component";
import { RechazarFichajeComponent } from "./rechazar-fichaje/rechazar-fichaje.component";
import { CrearJugadorComponent } from "./crear-jugador/crear-jugador.component";
import { CreatePlayerDelegadoComponent } from "./create-player-delegado/create-player-delegado.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatSelectModule,
    MatDialogModule,
    MatCardModule,
    MatButtonModule,
    NgxImageDisplayModule.forRoot(),
    LightboxModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatInputModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatToolbarModule,
    MatIconModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    EliminarItemComponent,
    ValidarJugadorComponent,
    CrearJugadorComponent,
    AgregarItemComponent,
    HistorialJugadorComponent,
    PagoJugadorComponent,
    CredencialJugadorComponent,
    RechazarFichajeComponent,
    CreatePlayerDelegadoComponent,
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    EliminarItemComponent,
    ValidarJugadorComponent,
    CrearJugadorComponent,
    AgregarItemComponent,
    HistorialJugadorComponent,
    PagoJugadorComponent,
    RechazarFichajeComponent,
    CreatePlayerDelegadoComponent,
  ],
  entryComponents: [
    EliminarItemComponent,
    ValidarJugadorComponent,
    CrearJugadorComponent,
    AgregarItemComponent,
    HistorialJugadorComponent,
    PagoJugadorComponent,
    RechazarFichajeComponent,
    CreatePlayerDelegadoComponent,
  ],
  providers: [MatDatepickerModule],
})
export class ComponentsModule {}
