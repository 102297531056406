import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "app/core/auth/authentication.service";
import { HttpService } from "app/core/http/http.service";

import { AngularFireAuth } from "@angular/fire/auth";
import firebase from "firebase";
import { environment } from "environments/environment";
import { Usuario } from "app/core/models/Usuario";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  public appName = environment.titleApp;
  login() {
    this.incorrectData = false;
    this.auth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(async (data: firebase.auth.UserCredential) => {
        const idToken = await data.user.getIdToken();
        this.http.post("/adm/login", { idToken }).subscribe(
          (user: Usuario) => {
            this.authenticationService.saveResLoginData(idToken, {
              ...user,
              name: data.user.displayName,
            });
            this.router.navigate(["/"]);
          },
          (err) => (this.incorrectData = true)
        );
      })
      .catch((err) => {
        this.incorrectData = true;
      });
  }
  logout() {
    this.auth.signOut();
  }

  constructor(
    public auth: AngularFireAuth,
    private authenticationService: AuthenticationService,
    private router: Router,
    private http: HttpService
  ) {}

  incorrectData = false;

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.router.navigateByUrl("");
    }
  }

  navigate() {
    this.router.navigateByUrl("");
  }
}
