import { HttpHeaders } from "@angular/common/http";

export const APP_TITLE = "LIFA Clubes";

export const API_CONST = {
  domain: "",
};

export const END_POINT = {
  V3: {
    personDni: "/v3/person/dni",
    personId: "/v3/person/id",
    fichajes: "/v3/person/fichaje",
    playerFichajes: "/v3/person/player/fichaje",
    funcionariosFichajes: "/v3/person/funcionario/fichaje",
    funcionarios: "/v3/funcionario/",
    person: "/v3/person",
    jugador: "/v3/person/player",
    delegado: "/v3/person/funcionario",
  },
  FICHAES_V2: {
    rechazar: "/v2/fichajes/rechazar",
    aprobar: "/v2/fichajes/aprobar",
    getPendientes: "/v2/fichajes/pendientes",
  },
  HABILITADOS_V2: {
    habilitarJugadores: "/v2/jugadores/habilitar",
    deshabilitarJugadores: "/v2/jugadores/deshabilitar",
  },

  AWS: {
    signedUrl: "/v3/signedUrl",
  },
  FICHAES_V3: {
    aprobar: "/v3/fichajes/aprobar",
    getPendientes: "/v3/fichajes/pendientes",
    getFichaje: "/v3/fichajes/pendientes/",
  },
  HABILITADOS_V3: {
    habilitarJugadores: "/v3/jugadores/habilitar",
    deshabilitarJugadores: "/v3/jugadores/deshabilitar",
  },
  APP_CLUBES: {
    getTeamUsersWeb: "/v3/app/team",
    deshabilitarJugadores: "/v3/jugadores/deshabilitar",
  },

  // Sesion
  login: API_CONST.domain + "/login",

  jugadoresSinValidar: API_CONST.domain + "/jugadores/validacion",
  cantidadJugadoresSinValidar:
    API_CONST.domain + "/jugadores/validacion/cantidad",

  equipos: API_CONST.domain + "/team",
  categorias: API_CONST.domain + "/category",
  filtarJugadores: API_CONST.domain + "/jugadores/filtrar",
  marcarPago: API_CONST.domain + "/jugadores/pago",
  exportarJugadores: API_CONST.domain + "/jugadores/exportar",
  recategorizacion: API_CONST.domain + "/recategorizacion",
  recategorizacionLogger: API_CONST.domain + "/recategorizacionlogger",

  fichajesTotales: API_CONST.domain + "/app-clubes/fichajesTotales",
  fichajesBorrados: API_CONST.domain + "/app-clubes/fichajesBorrados",
  fichajesCountTotales: API_CONST.domain + "/app-clubes/fichajesTotales/count",
  fichajesCountBorrados:
    API_CONST.domain + "/app-clubes/fichajesBorrados/count",

  //Noticias
  noticia: API_CONST.domain + "/app-clubes/noticia",

  //getUsersByPushid
  usersByPush: API_CONST.domain + "/users/byPush",

  //	//getUsersByPushid
  notificaciones: API_CONST.domain + "/notificaciones",
};

export const TOKEN_HEADER = "Authorization";
export const TOKEN_PREFIX = "Bearer ";

export const DEFAULT_HEADERS = new HttpHeaders({
  "Content-Type": "text/plain",
});

export const USUARIO_DATA_LOCALSTORAGE = "_em_lifa_C"; // USUARIO data.

export const CATEGORIES_TEAM_LOCALSTORAGE = "_cat_lifa_C"; // USUARIO data.

export const CREDENTIALS_TEAM_LOCALSTORAGE = "_cred_lifa_C"; // USUARIO data.

export const TEAM_LOCALSTORAGE = "_team_lifa_C"; // USUARIO data.
