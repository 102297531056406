import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-agregar-item',
  templateUrl: './agregar-item.component.html',
  styleUrls: ['./agregar-item.component.css']
})
export class AgregarItemComponent implements OnInit {


  categoriaSelected = new FormControl()
  
  constructor(
    public dialogRef: MatDialogRef<AgregarItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    public onNoClick(){
      this.dialogRef.close()
    }

  ngOnInit(): void {
  }

}

