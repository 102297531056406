import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { END_POINT } from "app/core/constants/constants";
import { HttpService } from "app/core/http/http.service";
import {
  FichajeRejectedBy,
  FichajeStatus,
  Person,
} from "app/core/models/Fichajes";

@Component({
  selector: "app-rechazar-jugador",
  templateUrl: "./rechazar-fichaje.component.html",
  styleUrls: ["./rechazar-fichaje.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RechazarFichajeComponent implements OnInit {
  public fichajeId = null;
  public person: Person;
  public isFuncionario: boolean = false;

  constructor(
    public http: HttpService,
    public dialogRef: MatDialogRef<RechazarFichajeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const { fichajeId, person, isFuncionario } = data;
    this.fichajeId = fichajeId;
    this.person = person;
    this.isFuncionario = isFuncionario || false;
  }
  ngOnInit(): void {}

  public rejectedBy: FichajeRejectedBy;
  public reason: string;

  public readonly REJECTED_BY = FichajeRejectedBy;

  public rechazarFichaje(rejectedBy: FichajeRejectedBy) {
    this.http
      .post(
        (this.isFuncionario
          ? END_POINT.V3.funcionariosFichajes
          : END_POINT.V3.playerFichajes) + "/validate",
        {
          idFichaje: this.fichajeId,
          status: FichajeStatus.REJECTED,
          rejectedBy: rejectedBy,
          reason: this.reason,
        }
      )
      .subscribe(
        (data) => {
          this.dialogRef.close(true);
        },
        (err) => {
          alert(
            `Error al Rejectar al ${
              this.isFuncionario ? "Administrativo" : "Jugador"
            }`
          );
        }
      );
  }

  public onNoClick() {
    this.dialogRef.close();
  }
}
