import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatAccordion } from "@angular/material/expansion";
import { END_POINT } from "app/core/constants/constants";
import { HttpService } from "app/core/http/http.service";
import {
  Person,
  PersonFichaje,
  PersonFichajePlayer,
} from "app/core/models/Fichajes";
import { GeneralService } from "app/services/general.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { Lightbox } from "ngx-lightbox";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { RechazarFichajeComponent } from "../rechazar-fichaje/rechazar-fichaje.component";
import { Category, Team } from "app/core/models/Backend";

const enum REASSONS {
  FOTO_DNI_INVALIDO = 1,
  FOTO_JUGADOR_INVALIDA = 2,
  JUGADOR_INVALIDO = 3,
}

const enum StatusFichaje {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
}

enum RejectedStatus {
  PHOTO_DNI = "PHOTO_DNI",
  PHOTO_SELFIE = "PHOTO_SELFIE",
  PHOTO_BOTH = "PHOTO_BOTH",
  FULL = "FULL",
}

@Component({
  selector: "app-validar-jugador",
  templateUrl: "./validar-jugador.component.html",
  styleUrls: ["./validar-jugador.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ValidarJugadorComponent implements OnInit {
  public isMobile: boolean;

  public loading = true;

  // Steps del Accordion (No es muy util)
  public validateIdentity: "inProgress" | "invalid" | "valid" = "inProgress";
  public validateDni: "inProgress" | "invalid" | "valid" = "inProgress";
  public validateSelfie: "inProgress" | "invalid" | "valid" = "inProgress";
  public validateCategory: "inProgress" | "invalid" | "valid" = "inProgress";
  public stepValidation = -1;

  // Campos para editar manualmente del person:
  public dniEdited: string;
  public nameEdited: string;
  public surnameEdited: string;
  public generoEdited: string;
  public birthdayEdited: string;
  public teamEdited: Team;
  public categoryEdited: Category;

  public credentialBg: string = "";

  @ViewChild(MatAccordion) accordion: MatAccordion;

  public fichaje: PersonFichajePlayer = null;
  public person: Person = null;

  public teams: Team[] = [];
  public teamFiltered = [];
  public teamSelected = null;

  public categories: Category[] = [];
  public categoriesFiltered = [];
  public categorySelected = null;

  public hasIdentityChecked: boolean = false;
  public checkIdentity: boolean = false;

  public isFuncionario = false;

  constructor(
    private deviceService: DeviceDetectorService,
    private generalService: GeneralService,
    public http: HttpService,
    public _lightbox: Lightbox,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ValidarJugadorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const { id, person, dniImage, avatar, team, category, funcionarioType } =
      data.fichaje;
    const { dni, name, surname, birthday, gender } = person;

    this.isFuncionario = data.isFuncionario || false;
    this.teams = data.teams;
    this.categories = data.categories;

    this.teamFiltered = data.teams;
    this.categoriesFiltered = data.categories;

    this.person = person;
    this.isMobile = !this.deviceService.isDesktop();
    this.fichaje = data.fichaje;
    this.dniEdited = dni;
    this.nameEdited = name;
    this.surnameEdited = surname;
    this.birthdayEdited = birthday;
    this.generoEdited = gender;

    this.teamEdited = team;
    this.teamSelected = team.id;

    this.categoryEdited = this.isFuncionario ? funcionarioType : category;
    this.categorySelected = this.isFuncionario
      ? funcionarioType.id
      : category?.id;
  }

  // Steps

  public setStep(index: number) {
    this.stepValidation = index;
  }

  public nextStep() {
    this.stepValidation++;
  }
  prevStep() {
    this.stepValidation--;
  }

  public readPerson() {
    this.hasIdentityChecked = true;
  }

  // Modo edición de información
  public editModelField = "";
  public editPerson(field: "dni" | "birthday" | "genero" | "name" | "surname") {
    this.editModelField = field;
  }

  public isValidationInProgress() {
    return (
      this.validateIdentity === "inProgress" ||
      this.validateDni === "inProgress"
    );
  }

  public isValidFichaje() {
    return this.validateIdentity === "valid" && this.validateDni === "valid";
  }

  public isEditModeEnabled(
    field:
      | "dni"
      | "birthday"
      | "genero"
      | "name"
      | "surname"
      | "team"
      | "category"
  ) {
    return field === this.editModelField;
  }

  public confirmEdit(
    field:
      | "dni"
      | "birthday"
      | "genero"
      | "name"
      | "surname"
      | "Team"
      | "Category"
  ) {
    const nameFieldEdited = field + "Edited";
    this.person[field] = this[nameFieldEdited];
    this.editModelField = "";
  }

  public cancelEdit(
    field:
      | "dni"
      | "birthday"
      | "genero"
      | "name"
      | "surname"
      | "team"
      | "category"
  ) {
    const nameFieldEdited = field + "Edited";
    this[nameFieldEdited] = this.person[field];
    this.editModelField = "";

    if (field === "team") {
      this.teamSelected = this.fichaje.team.id;
    }
    if (field === "category") {
      this.categorySelected = this.fichaje.category.id;
    }
  }

  public rechazarFichaje() {
    this.openDialog();
  }

  onKeyTeam(value) {
    this.teamFiltered = this.searchTeam(value);
  }

  onKeyCategory(value) {
    this.categoriesFiltered = this.searchCategory(value);
  }

  public eliminarFichaje() {
    this.http
      .delete(
        this.isFuncionario
          ? END_POINT.V3.funcionariosFichajes
          : END_POINT.V3.playerFichajes,
        this.fichaje.id
      )
      .subscribe(
        (data) => {
          this.dialogRef.close(true);
        },
        (err) => {
          alert("Error al validar al jugador");
        }
      );
  }

  public aprobarFichaje() {
    if (!this.fichaje) {
      return false;
    }

    /**
     * 
     *  newName: this.nameEdited,
        newSurname: this.surnameEdited,
        newBirthday: this.birthdayEdited,
        newGender: this.generoEdited,
        newDni: this.dniEdited,
     */

    this.http
      .post(
        (this.isFuncionario
          ? END_POINT.V3.funcionariosFichajes
          : END_POINT.V3.playerFichajes) + "/validate",
        {
          idFichaje: this.fichaje.id,
          status: StatusFichaje.APPROVED,
        }
      )
      .subscribe(
        (data) => {
          this.dialogRef.close(true);
        },
        (err) => {
          alert("Error al validar al jugador");
        }
      );
  }

  public handleValidateIdentity(valid: boolean) {
    if (valid) {
      this.validateIdentity = "valid";
      this.nextStep();
    } else {
      this.validateIdentity = "invalid";
      this.stepValidation = -1;
    }
  }
  public handleValidateDni(valid: boolean) {
    if (valid) {
      this.validateDni = "valid";
      this.nextStep();
    } else {
      this.validateDni = "invalid";
      this.stepValidation = -1;
    }
  }

  public handleValidateSelfie(valid: boolean) {
    if (valid) {
      this.validateSelfie = "valid";
      this.nextStep();
    } else {
      this.validateSelfie = "invalid";
      this.stepValidation = -1;
    }
  }

  public onNoClick() {
    this.dialogRef.close();
  }

  public verInfoJugador() {}

  openDialog(): void {
    const dialogRef = this.dialog.open(RechazarFichajeComponent, {
      width: "auto",
      height: "auto",
      maxWidth: "100vw",
      maxHeight: "100vh",
      panelClass: "fichajes-rechazar-dialog",
      data: {
        fichajeId: this.fichaje.id,
        person: this.person,
        isFuncionario: this.isFuncionario,
      },
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dialogRef.close(true);
      }
    });
  }

  public imagenAvatar = null;
  public imagenDni = null;

  public infoPerson = null;

  ngOnInit(): void {
    const signedUrlAvatar = this.generalService
      .getSignedUrl(this.fichaje.avatar, "selfie")
      .pipe(catchError((err) => of({ url: "" })));
    const signedUrlDNI = this.generalService
      .getSignedUrl(this.fichaje.dniImage, "dni")
      .pipe(catchError((err) => of({ url: "" })));
    const signedBgCredential = this.generalService.getSignedUrl(
      "https://futsala-public.s3.sa-east-1.amazonaws.com/credencial.jpeg",
      "selfie"
    );

    const infoPerson = this.http
      .get(`${END_POINT.V3.fichajes}/${this.person.id}/info`, {
        idFichaje: this.fichaje.id,
        status: StatusFichaje.APPROVED,
      })
      .pipe(catchError(() => of(null)));

    forkJoin([
      signedUrlAvatar,
      signedUrlDNI,
      signedBgCredential,
      infoPerson,
    ]).subscribe(([avatar, dni, credential, infoPerson]: any) => {
      console.log(dni, avatar);
      this.imagenDni = dni.url;
      this.imagenAvatar = avatar.url;

      this.fichaje.avatar = avatar.url;
      this.fichaje.dniImage = dni.url;
      this.credentialBg = credential.url;

      this.infoPerson = infoPerson;
      if (this.infoPerson?.warn) {
        this.checkIdentity = true;
        this.infoPerson.table = infoPerson.table.filter(
          (item) => item.id !== this.fichaje.id
        );
      }

      this.loading = false;
    });
  }

  searchCategory(value: string) {
    let filter = value.toLowerCase();
    return this.categories.filter((option) =>
      option.name.toLowerCase().includes(filter)
    );
  }
  searchTeam(value: string) {
    let filter = value.toLowerCase();
    return this.teams.filter((option) =>
      option.name.toLowerCase().includes(filter)
    );
  }
}
