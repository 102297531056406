import {
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "credencial-jugador",
  templateUrl: "./credencial-jugador.component.html",
  styleUrls: ["./credencial-jugador.component.scss"],
})
export class CredencialJugadorComponent implements OnInit {
  @Input() fichaje;
  @Input() bgCredential;
  @Input() bgSelfie;
  ngOnInit(): void {}
}
