import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatAccordion } from "@angular/material/expansion";
import { END_POINT } from "app/core/constants/constants";
import { HttpService } from "app/core/http/http.service";
import {
  Person,
  PersonFichaje,
  PersonFichajePlayer,
} from "app/core/models/Fichajes";
import { GeneralService } from "app/services/general.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { Lightbox } from "ngx-lightbox";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { RechazarFichajeComponent } from "../rechazar-fichaje/rechazar-fichaje.component";
import { Category, Team } from "app/core/models/Backend";
import { CreatePlayerDelegadoComponent } from "../create-player-delegado/create-player-delegado.component";
import { EliminarItemComponent } from "../eliminar-item/eliminar-item.component";
import { EliminarItem } from "../eliminar-item/eliminar-item.model";

const enum REASSONS {
  FOTO_DNI_INVALIDO = 1,
  FOTO_JUGADOR_INVALIDA = 2,
  JUGADOR_INVALIDO = 3,
}

const enum StatusFichaje {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
}

enum RejectedStatus {
  PHOTO_DNI = "PHOTO_DNI",
  PHOTO_SELFIE = "PHOTO_SELFIE",
  PHOTO_BOTH = "PHOTO_BOTH",
  FULL = "FULL",
}

@Component({
  selector: "app-crear-jugador",
  templateUrl: "./crear-jugador.component.html",
  styleUrls: ["./crear-jugador.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CrearJugadorComponent implements OnInit {
  public isMobile: boolean;

  public loading = false;

  public newDni = "";

  // Campos para editar manualmente del person:
  public dniEdited: string;
  public nameEdited: string;
  public surnameEdited: string;
  public generoEdited: string;
  public birthdayEdited: string;
  public teamEdited: Team;
  public categoryEdited: Category;

  public credentialBg: string = "";

  @ViewChild(MatAccordion) accordion: MatAccordion;

  public fichaje: PersonFichajePlayer = null;
  public person: Person = {} as Person;

  public teams: Team[] = [];
  public teamFiltered = [];
  public teamSelected = null;

  public categories: Category[] = [];
  public categoriesFiltered = [];
  public categorySelected = null;

  public hasIdentityChecked: boolean = false;
  public checkIdentity: boolean = false;

  public isFuncionario = false;

  constructor(
    private deviceService: DeviceDetectorService,
    private generalService: GeneralService,
    public http: HttpService,
    public _lightbox: Lightbox,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CrearJugadorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public readPerson() {
    this.hasIdentityChecked = true;
  }

  // Modo edición de información
  public editModelField = "";
  public editPerson(field: "dni" | "birthday" | "genero" | "name" | "surname") {
    this.editModelField = field;
  }

  public isEditModeEnabled(
    field:
      | "dni"
      | "birthday"
      | "genero"
      | "name"
      | "surname"
      | "team"
      | "category"
  ) {
    return field === this.editModelField;
  }

  public confirmEdit(
    field:
      | "dni"
      | "birthday"
      | "genero"
      | "name"
      | "surname"
      | "Team"
      | "Category"
  ) {
    const nameFieldEdited = field + "Edited";
    this.person[field] = this[nameFieldEdited];
    this.editModelField = "";
  }

  public cancelEdit(
    field:
      | "dni"
      | "birthday"
      | "genero"
      | "name"
      | "surname"
      | "team"
      | "category"
  ) {
    const nameFieldEdited = field + "Edited";
    this[nameFieldEdited] = this.person[field];
    this.editModelField = "";

    if (field === "team") {
      this.teamSelected = this.fichaje.team.id;
    }
    if (field === "category") {
      this.categorySelected = this.fichaje.category.id;
    }
  }

  public rechazarFichaje() {
    this.openDialog();
  }

  onKeyTeam(value) {
    this.teamFiltered = this.searchTeam(value);
  }

  onKeyCategory(value) {
    this.categoriesFiltered = this.searchCategory(value);
  }

  public eliminarFichaje() {
    this.http
      .delete(
        this.isFuncionario
          ? END_POINT.V3.funcionariosFichajes
          : END_POINT.V3.playerFichajes,
        this.fichaje.id
      )
      .subscribe(
        (data) => {
          this.dialogRef.close(true);
        },
        (err) => {
          alert("Error al crear al jugador");
        }
      );
  }

  public createDelegado() {
    this.create("Delegado");
  }

  public createPlayer() {
    this.create("Jugador");
  }

  public create(type) {
    const dialogRef = this.dialog.open(CreatePlayerDelegadoComponent, {
      width: "auto",
      height: "auto",
      maxWidth: "100vw",
      maxHeight: "100vh",
      panelClass: "fichajes-rechazar-dialog",
      data: {
        type,
      },
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.type === "Jugador") {
          this.generalService
            .createPlayer(this.person.id, result.team, result.category)
            .subscribe(() => {
              this.getInfoByDni();
            });
        } else {
          this.generalService
            .createDelegado(this.person.id, result.team, result.category)
            .subscribe(() => {
              this.getInfoByDni();
            });
        }
      }
    });
  }

  deletePlayer(id): void {
    const dialogRef = this.dialog.open(EliminarItemComponent, {
      width: "250px",
      data: {
        title: "Deseas eliminar al jugador?",
        subtitle: "Estás a punto de eliminar al jugador",
        textNo: "Volver",
        textOk: "Eliminar",
      } as EliminarItem,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.generalService.borrarPlayer(id).subscribe(() => {
        this.getInfoByDni();
      });
    });
  }

  deleteDelegado(id): void {
    const dialogRef = this.dialog.open(EliminarItemComponent, {
      width: "250px",
      data: {
        title: "Deseas eliminar al Delegado?",
        subtitle: "Estás a punto de eliminar al Delegado",
        textNo: "Volver",
        textOk: "Eliminar",
      } as EliminarItem,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.generalService.borrarDelegado(id).subscribe(() => {
        this.getInfoByDni();
      });
    });
  }

  deletePerson(): void {
    const dialogRef = this.dialog.open(EliminarItemComponent, {
      width: "250px",
      data: {
        title: "Deseas eliminar todas las referencias de la persona?",
        subtitle:
          "Estás a punto de eliminar todos los jugadores y delegados con el DNI asociado",
        textNo: "Volver",
        textOk: "Eliminar",
      } as EliminarItem,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.generalService.borrarPerson(this.newDni).subscribe(() => {
        this.dialog.closeAll();
      });
    });
  }

  public aprobarFichaje() {
    if (!this.fichaje) {
      return false;
    }

    /**
     * 
     *  newName: this.nameEdited,
        newSurname: this.surnameEdited,
        newBirthday: this.birthdayEdited,
        newGender: this.generoEdited,
        newDni: this.dniEdited,
     */

    this.http
      .post(
        (this.isFuncionario
          ? END_POINT.V3.funcionariosFichajes
          : END_POINT.V3.playerFichajes) + "/validate",
        {
          idFichaje: this.fichaje.id,
          status: StatusFichaje.APPROVED,
        }
      )
      .subscribe(
        (data) => {
          this.dialogRef.close(true);
        },
        (err) => {
          alert("Error al crear al jugador");
        }
      );
  }

  public onNoClick() {
    this.dialogRef.close();
  }

  public verInfoJugador() {}

  openDialog(): void {
    const dialogRef = this.dialog.open(RechazarFichajeComponent, {
      width: "auto",
      height: "auto",
      maxWidth: "100vw",
      maxHeight: "100vh",
      panelClass: "fichajes-rechazar-dialog",
      data: {
        fichajeId: this.fichaje.id,
        person: this.person,
        isFuncionario: this.isFuncionario,
      },
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dialogRef.close(true);
      }
    });
  }

  public imagenAvatar = null;
  public imagenDni = null;
  public infoPerson = null;

  public personHistory = [];
  // No

  public dniImageLoaded = null;

  onImageDniChanged(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      this.generalService
        .uploadPhoto(file, file.name, "dni", this.newDni)
        .subscribe((data: any) => {
          console.log(data);
          this.person.dniImage = data.url;
          this.getSigned();
        });

      reader.readAsDataURL(file);

      reader.onload = () => {
        this.person.dniImage = reader.result as string;
        this.dniImageLoaded = reader.result as string;
      };
    }
  }

  getSigned() {
    const signedUrlDni = this.generalService
      .getSignedUrl(this.person.dniImage, "selfie")
      .pipe(catchError((err) => of({ url: "" })));

    forkJoin([signedUrlDni]).subscribe(([dni]: any) => {
      this.imagenDni = dni.url;
    });
  }

  updatePerson() {
    this.generalService
      .updatePerson(
        this.person.id,
        this.person.name,
        this.person.surname,
        this.person.birthday,
        this.person.dniImage,
        this.person.avatar,
        this.person.gender
      )
      .subscribe((data) => {
        this.getInfoByDni();
      });
  }
  onAvatarChanged(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      this.generalService
        .uploadPhoto(file, file.name, "selfie", this.newDni)
        .subscribe((data: any) => {
          console.log(data);
          this.person.avatar = data.url;
        });

      reader.readAsDataURL(file);

      reader.onload = () => {
        this.person.avatar = reader.result as string;
      };
    }
  }

  public getInfoByPersonId(personId: string) {
    this.http
      .get(`${END_POINT.V3.personId}/${personId}`)
      .pipe(catchError(() => of(null)))
      .subscribe((data) => {
        if (!data) {
          this.person = {
            name: "",
            surname: "",
            dni: this.newDni,
            birthday: "",
            gender: "",
            extra: {
              day: "",
              month: "",
              year: "",
            },
          };
        } else {
          this.person = data.person;
          this.getSigned();
          this.personHistory = data.history;
          this.newDni = data.person.dni;
        }
      });
  }

  public getInfoByDni() {
    this.http
      .get(`${END_POINT.V3.personDni}/${this.newDni}`)
      .pipe(catchError(() => of(null)))
      .subscribe((data) => {
        if (!data) {
          this.person = {
            name: "",
            surname: "",
            dni: this.newDni,
            birthday: "",
            gender: "",
            extra: {
              day: "",
              month: "",
              year: "",
            },
          };
        } else {
          this.person = data.person;
          this.getSigned();
          this.personHistory = data.history;
        }
      });
  }
  ngOnInit(): void {
    const signedBgCredential = this.generalService.getSignedUrl(
      "https://futsala-public.s3.sa-east-1.amazonaws.com/credencial.jpeg",
      "selfie"
    );

    forkJoin([signedBgCredential]).subscribe(([credential]: any) => {
      this.credentialBg = credential.url;
      this.loading = false;
    });

    if (this.data.playerSelected) {
      if (this.data.playerSelected.person?.id) {
        this.getInfoByPersonId(this.data.playerSelected.person?.id);
      } else {
        this.newDni = this.data.playerSelected.dni;
        this.getInfoByDni();
      }
    }
  }

  searchCategory(value: string) {
    let filter = value.toLowerCase();
    return this.categories.filter((option) =>
      option.name.toLowerCase().includes(filter)
    );
  }
  searchTeam(value: string) {
    let filter = value.toLowerCase();
    return this.teams.filter((option) =>
      option.name.toLowerCase().includes(filter)
    );
  }
}
