import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalstorageService } from '../general/localstorage.service';
import { CATEGORIES_TEAM_LOCALSTORAGE, CREDENTIALS_TEAM_LOCALSTORAGE, TEAM_LOCALSTORAGE, TOKEN_HEADER, USUARIO_DATA_LOCALSTORAGE } from '../constants/constants';
import { Usuario } from '../models/Usuario';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	public permisos: string[] = [];

	constructor(private localStorageService: LocalstorageService, private router: Router) { }


	public successLogin(data){

	}
	
	public saveResLoginData(token: string, usuario: Usuario): void {
        this.permisos = usuario.permisos.map((x)=>x.value);

		this.localStorageService.setItem(TOKEN_HEADER, token);
		this.localStorageService.setItem(USUARIO_DATA_LOCALSTORAGE, JSON.stringify(usuario));
	}

	public getToken(): string {
		return this.localStorageService.getItem(TOKEN_HEADER);
	}

	public setNewToken(token): void {
		this.localStorageService.setItem(TOKEN_HEADER, token);

	}

	public isLoggedIn(): boolean {
		return !!this.getToken();
	}

	public getUsuario(): Usuario {
		return JSON.parse(this.localStorageService.getItem(USUARIO_DATA_LOCALSTORAGE)) || {};
	}

	public logOut(): void {
		this.localStorageService.removeItem(TOKEN_HEADER);
		this.localStorageService.removeItem(USUARIO_DATA_LOCALSTORAGE);
		this.localStorageService.removeItem(CATEGORIES_TEAM_LOCALSTORAGE);
		this.localStorageService.removeItem(TEAM_LOCALSTORAGE);
		this.localStorageService.removeItem(CREDENTIALS_TEAM_LOCALSTORAGE);
		this.router.navigate(['login']);
	}
}
