import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize,map } from 'rxjs/operators';
import { HttpService } from '../http/http.service';
import { Usuario } from '../models/Usuario';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthenticationService, public router: Router, private http: HttpService
    ) {}
  canActivate() {
    return new Observable<boolean>((obs) => {
      this.http.get('/adm/token').pipe(finalize(() =>obs.complete() )).subscribe((item:Usuario) => {

        this.auth.permisos = item.permisos.map((permiso)=>permiso.value);
        obs.next(true)
      },() => {
        this.auth.logOut()
        obs.next(false)
      })
    })
  }
}