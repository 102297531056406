import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { END_POINT } from "app/core/constants/constants";
import { HttpService } from "app/core/http/http.service";
import {
  FichajeRejectedBy,
  FichajeStatus,
  Person,
} from "app/core/models/Fichajes";
import { GeneralService } from "app/services/general.service";

@Component({
  selector: "app-create-player-delegado",
  templateUrl: "./create-player-delegado.component.html",
  styleUrls: ["./create-player-delegado.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreatePlayerDelegadoComponent implements OnInit {
  public type: string;

  public equiposSelected = null;
  public categorySelected = null;

  public categories = [];
  public funcionarios = [];
  public teams = [];

  constructor(
    public http: HttpService,
    private general: GeneralService,
    public dialogRef: MatDialogRef<CreatePlayerDelegadoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const { type } = data;

    this.type = type;
  }
  ngOnInit(): void {
    this.general.getEquipos().subscribe((data) => {
      this.teams = data;
      this.teamFiltered = [...data];
    });
    this.general.getFuncionarios().subscribe((data) => {
      this.funcionarios = data;
      this.funcionriosFiltered = [...data];
    });

    this.general.getCategorias().subscribe((data) => {
      this.categories = data;
      this.categoriesFiltered = [...data];
    });
  }

  public onNoClick() {
    this.dialogRef.close();
  }

  public create() {
    this.dialogRef.close({
      team: this.equiposSelected,
      category: this.categorySelected,
      type: this.type,
    });
  }

  public teamFiltered = [];
  public categoriesFiltered = [];
  public funcionriosFiltered = [];

  onKeyTeam(value) {
    this.teamFiltered = this.searchTeam(value);
  }

  onKeyCategory(value) {
    this.categoriesFiltered = this.searchCategory(value);
    this.funcionriosFiltered = this.searchCategory(value);
  }

  searchCategory(value: string) {
    let filter = value.toLowerCase();
    return this.categories.filter((option) =>
      option.name.toLowerCase().includes(filter)
    );
  }

  searchTeam(value: string) {
    let filter = value.toLowerCase();
    return this.teams.filter((option) =>
      option.name.toLowerCase().includes(filter)
    );
  }
}
